import { ApolloClient, Resolvers } from 'apollo-boost';
import Router from 'next/router';

import { IS_LOGGED_IN, IS_SIDEBAR_OPEN, LOCAL_GET_FLASH, LOCAL_GET_LOADING } from './queries';
import routes from './routes';
import safeLocalStorage from './safeLocalStorage';
import safeSessionStorage from './safeSessionStorage';

const resolvers: Resolvers = {
  Query: {
    isLoggedIn: async () => {
      const token = safeLocalStorage.getItem('token');
      return Boolean(token);
    },
    localGetFlash: async (_, variables, { cache }: ApolloClient<any>) => {
      return cache.readQuery({ query: LOCAL_GET_FLASH });
    },
    localGetLoading: async (_, variables, { cache }: ApolloClient<any>) => {
      return cache.readQuery({ query: LOCAL_GET_LOADING });
    },
    isSidebarOpen: async (_, variables, { cache }: ApolloClient<any>) => {
      const sidebarState = safeLocalStorage.getItem('isSidebarOpen');

      if (sidebarState === null) {
        return true;
      }
      return sidebarState;
    },
  },

  Mutation: {
    localLogin: async (_, variables, { cache }) => {
      const accessToken = variables?.accessToken;
      const refreshToken = variables?.refreshToken;

      if (accessToken && refreshToken) {
        safeLocalStorage.setItem('token', accessToken);
        safeSessionStorage.setItem('token', refreshToken);

        cache.writeQuery({
          query: IS_LOGGED_IN,
          data: {
            isLoggedIn: true,
          },
        });
      }
    },
    localLogout: async (_, variables, { cache }: ApolloClient<any>) => {
      safeLocalStorage.clear();
      safeSessionStorage.clear();
      await cache.reset();

      cache.writeQuery({
        query: IS_LOGGED_IN,
        data: {
          isLoggedIn: false,
        },
      });

      Router.push(routes.LOGIN);
    },
    localSetFlash: async (_, variables, { cache }: ApolloClient<any>) => {
      cache.writeQuery({
        query: LOCAL_GET_FLASH,
        data: {
          localGetFlash: {
            payload: variables?.payload,
            type: variables?.type,
          },
        },
      });
    },
    localSetLoading: async (_, variables, { cache }: ApolloClient<any>) => {
      cache.writeQuery({
        query: LOCAL_GET_LOADING,
        data: {
          localGetLoading: variables?.loading,
        },
      });
    },
    setSidebar: async (_, variables, { cache }: ApolloClient<any>) => {
      cache.writeQuery({
        query: IS_SIDEBAR_OPEN,
        data: {
          isSidebarOpen: variables?.open,
        },
      });
      safeLocalStorage.setItem('isSidebarOpen', variables.open);
    },
  },
};

export default resolvers;
