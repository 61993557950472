import { ApolloProvider } from '@apollo/react-hooks';
import 'bootstrap/scss/bootstrap.scss';
import moment from 'moment';
import App, { AppContext, AppProps } from 'next/app';
import React from 'react';

import client from '../lib/client';
import '../lib/i18n';
import '../lib/sentry';
import '../lib/translations';
import '../scss/styles.scss';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  moment.locale('pt-BR');

  return (
    <ApolloProvider client={client}>
      <Component {...pageProps} />
    </ApolloProvider>
  );
}

/* Environment Variables on Runtime */

// eslint-disable-next-line functional/immutable-data
MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default MyApp;
