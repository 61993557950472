import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const dev = {
  URI: publicRuntimeConfig.URI_DEV,
};

const prod = {
  URI: publicRuntimeConfig.URI_PROD,
};

const environments = process.env.NODE_ENV === 'production' ? prod : dev;

export default environments;
